@import "../../global.scss";

.contact {
  background-color: $mainGrey; //rgb(47, 110, 47);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 900px;
  min-height: 700px;
}

.contact-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 55vh;
  min-height: 400px;
  width: 60vw;

  background-color: white;

  textarea,
  input {
    -webkit-appearance: none;
    border: 0px solid grey;
    box-shadow: 2px 2px 0px $mainGrey, -2px 2px 5px black;

    border-radius: 20px;

    margin: 10px;
    width: 90%;
    height: 20px;
  }
  textarea {
    height: 100px;
    padding-top: 15px;
    resize: none;
  }
  .form-button {
    display: flex;
    justify-content: center;
  }
  .submit-button-div {
    display: flex;
    align-items: center;
  }
  button {
    margin: 10px auto;
    width: 50%;
    height: 25px;
    border-radius: 10px;
    border: none;
    &:hover {
      -webkit-box-shadow: inset 0px 0px 24px 5px $mainGrey;
      box-shadow: inset 0px 0px 24px 5px $mainGrey;
      transition: all 1s ease;
    }
  }
  form {
    width: 80%;
    margin-top: 20px;
  }
}

.error-print {
  padding: 0px 15px;
}

@media only screen and (max-width: 640px) {
  .contact {
    height: 1000px !important;
    min-height: 900px;
  }
  .contact-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 90vw;
    height: 65vh;
    margin-top: 100px;
  }
  form {
    width: 90%;
    margin-top: 20px;
  }
}
