.app {
  height: 100vh;
  min-height: 100vh;
  width: 100vw;
  overflow-x: hidden;
  .sections {
    width: 100%;
    height: 100%;
    // background-color: chocolate;
    position: relative;
    top: 12vh;

    scroll-snap-type: y proximity;
    overflow: scroll;
    scrollbar-width: none; //firefox
    scroll-behavior: smooth;

    > * {
      width: 100%;
      scroll-snap-align: start;
      overflow-x: hidden;
    }
    overflow-x: hidden;
  }
  @media only screen and (max-width: 640px) {
    .app {
      overflow-x: hidden;
    }
    .sections {
      height: 100vh;
      width: 100%;
      top: 80px;
      scroll-snap-type: none;
      overflow-x: hidden;
    }
    > * {
      width: 100%;
      height: 100vh;

      scroll-snap-align: none;
      overflow-x: hidden;
    }
  }
}
