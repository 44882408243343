@import "../../global.scss";

.about {
  overflow-x: hidden;

  background-color: $mainGrey;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: calc(100vh + 100px);
  min-height: 1400px;
  position: relative;
}

.about-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 20px;

  width: 80vw;
  height: 100%;

  h1 {
    font-size: 120px;
    height: 180px;
    color: white;
    // text-shadow: 2.5px 2.5px 0px $mainBlue;
    margin-top: 10px;
    z-index: 3;
  }
}

.about-text-container {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  height: 30%;
  min-height: 350px;
  width: 100%;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 15px;
  z-index: 2;
  .centering-div {
    font-size: 1.6em;
    height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    color: white;
  }
}

#tsparticles {
  z-index: 1;
  height: 95%;
  position: absolute;
  width: 100vw;
}
canvas {
  display: block;
}

.icons-container {
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  width: 100%;
  height: 400px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.icon-group {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  overflow: visible;
}
.icon-group-two {
  margin: auto;
}
.icon-group-one {
  margin: auto;
}
.icon-wrapper {
  width: 130px;
  height: 130px;
  padding: 0.5em;
  background: linear-gradient(
    90deg,
    rgba(3, 119, 189, 1) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  opacity: 1;
  z-index: 3;
  display: flex;
  align-items: center;
  border-radius: 10px;
}
.icon-box {
  background: $mainGrey;
  color: white;
  padding: 1.2em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 70%;
  width: 70%;
  img {
    flex: 0.8;
    z-index: 5;
    &:hover {
      transform: scale(1.3);
    }
  }
  h2 {
    font-size: 1em;
  }
}

.picture:hover ~ .icon-hover {
  opacity: 0.75;
  cursor: crosshair;
}
.picture:hover ~ h2 {
  opacity: 1;
  cursor: crosshair;
  z-index: 2;
}

@media only screen and (max-width: 800px) {
  .about-wrapper {
    overflow-x: hidden;
    h1 {
      font-size: 6em;
      height: 130px;
    }
  }
  .about-text-container {
    background-color: rgb(0, 0, 0); //rgb(173, 172, 172);
    min-height: 100px;
    .centering-div {
      font-size: 1.25em;
    }
  }
}

//css for mobile
@media only screen and (max-width: 640px) {
  .about {
    height: 1500px;
    width: 100vw;
    justify-content: flex-start;
    word-wrap: break-word;
  }
  .about-wrapper {
    width: 90vw;
    height: 100%;
    padding-top: 20px;
    h1 {
      margin-bottom: 10px;
      z-index: 3;
      height: 25vh;
      min-height: 220px;
      font-size: 80px;
      overflow-y: visible;
    }
  }
  .icons-container {
    width: 100%;
    height: 700px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin: -10px auto 0px;
  }

  .icon-group {
    flex-direction: column;
    width: 40vw;
  }

  .icon-group-two {
    margin-top: 10vh;
    margin-left: 0px;
    overflow: visible;
  }
  .icon-group-one {
    margin-left: 0px;
  }
  .icon-wrapper {
    width: 100px;
    height: 100px;
    padding: 0.6em;
    background: linear-gradient(
      90deg,
      rgba(3, 119, 189, 1) 0%,
      rgba(255, 255, 255, 1) 100%
    );
    opacity: 1;
    z-index: 3;
    display: flex;
    align-items: center;
    border-radius: 10px;
  }
  .icon-box {
    background: $mainGrey;
    color: white;
    padding: 1em;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 70%;
    width: 70%;
    img {
      flex: 0.8;
      z-index: 5;
      max-width: 100%;
      max-height: 100%;
      &:hover {
        transform: scale(1.3);
      }
    }
    h2 {
      font-size: 1em;
    }
  }
  .about-text-container {
    display: flex;
    height: 50vh;
    .centering-div {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      height: 250px;
      overflow-y: visible;
      font-size: 1.2em;
      padding: 0px 8px;
      margin: auto auto;
    }
  }
  #tsparticles {
    height: 100%;
  }
}
