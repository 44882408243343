@import "../../global.scss";

.menu {
  min-width: 180px;
  width: 15vw;

  height: 100vh;
  background-color: white;
  color: $mainGrey;
  position: fixed;
  top: 0;
  right: -350px;

  z-index: 6;
  display: flex;
  border: 2px solid $mainGrey;

  //   flex-direction: column;
  //   justify-content: center;
  //   align-items: center;

  ul {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 0;
    list-style: none;
    font-size: 35px;
    color: $mainGrey;

    width: 99%;
    li {
      margin: 30px 0px;
      a {
        text-decoration: none;
        color: inherit;
        font-size: inherit;
      }
      &:hover {
        font-weight: 900;
      }
    }
  }
  .mobile-resume {
    display: none;
  }
  transition: all 1.5s ease;
  &.active {
    right: 0;
  }
}

@media only screen and (max-width: 640px) {
  .menu {
    width: 80vw;
    right: -105%;
    .mobile-resume {
      display: flex;
      text-decoration: underline;
    }
    &.active {
      width: 99%;
    }
  }
}
