@import "../../global.scss";

.portfolio {
  //component displaying projects
  background-color: $mainGrey;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: calc(100vh + 300px);
  min-height: 700px;
  h2 {
    margin: 30px 0 0 0;
    font-weight: 500;
    font-size: 50px;
    height: 70px;
    color: white;
  }
}

.projects {
  display: grid;
  grid-template-columns: repeat(2, 0.5fr);
  grid-template-rows: repeat(2, 0.8fr);
  column-gap: 20px;
  row-gap: 20px;
  margin: auto;
}
.project {
  //div container for each project
  // background-color: lightsalmon;
  background-color: black;
  width: 25vw;
  height: 45vh;
  border: 3px solid white;
  border-radius: 10px;

  display: flex;
  flex-direction: column;
  align-items: center;
  .img-container {
    position: relative;
    width: 75%;
    height: auto;
    flex: 0.5;
    display: flex;
    align-items: center;
    align-content: center;
    border-radius: 20px;
    margin: 20px auto;
    img {
      width: 100%;
      height: auto;
      border: 3px solid $mainGrey;
      border-radius: 10px;
    }
  }
  .overlay {
    //creates shadow over screenshot when hovered. Displays project name
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: 0.5s ease;
    background-color: $mainGrey;
  }
  .img-container:hover .overlay {
    opacity: 1;
  }

  .title {
    color: white;
    font-size: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
  }
  // img {
  //   //screenshot of the project
  //   flex: 0.5;
  //   margin: 10px 10px;
  //   border: 2px solid black;
  //   border-radius: 20px;
  //   width: 75%;
  //   height: auto;
  // }
}
.project-description {
  //text explanation of the project
  flex: 0.25;
  text-align: center;
  padding: 10px 5px;
  color: white;
}
.project-links {
  //links to github
  flex: 0.25;
  display: flex;
  align-items: center;
  flex-direction: row;
  .project-btn {
    background-color: $mainGrey;
    border-radius: 10px;
    height: 40px;
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    a {
      text-decoration: none;
      color: white;
    }
    &:hover {
      -webkit-box-shadow: inset 0px 0px 24px 5px white;
      box-shadow: inset 0px 0px 24px 5px white;
      transition: all 1s ease;
    }
  }
  .project-btn + .project-btn {
    margin-left: 30px;
  }
}
.project-title {
  display: none;
}

@media only screen and (max-width: 1300px) and (min-width: 640px) {
  .projects {
    width: 80vw;
    grid-template-columns: auto auto;
    align-items: center;
    overflow-x: visible;
    .project {
      width: 100%;
      padding-top: 10px;
    }
  }
}
@media only screen and (max-width: 640px) {
  .portfolio {
    height: calc(2200px - 50vw);
    margin-top: 0px;
    word-wrap: break-word;
    justify-content: flex-start;

    h2 {
      height: 100px;
    }
  }
  .projects {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 80%;
  }
  .project {
    width: 70vw;
    height: 400px;
    padding-top: 10px;
    margin-bottom: 20px;
  }
  .project-links {
    .project-btn {
      width: 20vw;
    }
  }
  .project-title {
    color: white;
    display: flex;
    justify-content: center;
    margin: 5px auto;
    font-size: 1.5em;
    font-weight: bold;
    width: 60%;
  }
}

@media only screen and (max-width: 515px) {
  .projects {
    height: 1750px;
  }
}

@media only screen and (max-width: 490px) {
  .portfolio {
    height: calc(2600px - 50vw);
  }
  .projects {
    height: 1950px;
  }
}

@media only screen and (max-width: 390px) {
  .portfolio {
    height: calc(2600px - 50vw);
  }
  .projects {
    height: 2450px;
    .trip-planner {
      height: 500px;
      .trip-planner-description {
        flex: 0.5;
      }
    }
  }
}
@media only screen and (max-width: 390px) {
  .portfolio {
    height: calc(2600px - 50vw);
  }
  .projects {
    height: 2450px;
    .project {
      height: 450px;
      .project-description {
        flex: 0.4;
      }
    }
    .trip-planner {
      height: 500px;
      .trip-planner-description {
        flex: 0.6;
      }
      .portfolio-project-description {
        flex: 0.5;
      }
    }
  }
}
