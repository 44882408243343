$mainGreen: rgb(56, 109, 56);
$mainBlue: #0377bd;
$mainGrey: #3b3b4a;

$mediumWidth: 700px;

@mixin mobile {
  @media (max-width: #{mediumWidth}) {
    @content;
  }
}
