@import "../../global.scss";

.intro {
  * {
    transition: all 1s ease;
  }
  width: 100vw;
  height: 88vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: black;
  font-weight: bold;
  font-size: 2rem;
  height: calc(100vh - 100px);
}

.intro::before {
  content: "";
  background: url(../../../public/images/forest.jpg) no-repeat center
    center/cover;
  position: absolute;
  opacity: 0.3;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 90vh;

  object-fit: cover;
}
.photo-container {
  display: none;
}
.photo-circle {
  background: url(../../../public/images/headshot.jpg);
  background-size: cover;
  background-position: 30% 30%; /* First value is from left and second is from top. You can use use number as well*/
  background-repeat: no-repeat;
  border-radius: 50%;
  width: 50%;
  min-width: 250px;
  height: 50%;
  min-height: 200px;
  opacity: 0.3;
  z-index: 2;

  &:hover {
    // border: 2px solid white;
    opacity: 1;
  }
  display: none;
}

.rectangle {
  height: 35%;
  width: 20%;
  position: absolute;
  top: 40%;
  left: 10%;
  background-color: rgb(38, 42, 38);
  opacity: 0;
  display: none;
}

.intro-text-container {
  padding-left: 100px;

  height: 100%;
  width: 100%;
  flex: 0.8;
  display: flex;
  flex-direction: column;

  a {
    padding-left: 120px;

    flex: 0.3;
    height: 100px;
    width: 100px;
    text-decoration: none;
    color: $mainGrey;

    animation: up-down 1.75s infinite alternate
      cubic-bezier(0.01, 0.01, 1, 0.07);

    @keyframes up-down {
      100% {
        transform: translateY(20%);
      }
    }
    &:hover {
      animation-play-state: paused;
    }
    svg {
      height: 100%;
      width: 100%;
    }
  }
}

.intro-text {
  flex: 0.7;
  padding-top: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  h3,
  h2,
  h1 {
    height: 12vh;
    font-size: 2.5em;
  }
  h3 {
    color: $mainBlue;
    span {
      color: inherit;
    }
    .ityped-cursor {
      animation: blink 10s infinite;
    }
    @keyframes blink {
      // 50% {
      //   opacity: 1;
      // }
      100% {
        opacity: 0;
      }
    }
  }
}
.external-links {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
  flex: 0.125;
  bottom: 0px;
  z-index: 2;
  margin-bottom: 40px;
  margin-right: 5px;
  a {
    width: 100%;
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: 0;
    text-decoration: none;
    img {
      width: 90%;
      max-width: 75px;
      float: right;
    }
    .resume-box {
      display: block;
      text-decoration: none;
      color: $mainGrey;
      width: 90%;
      font-size: 20px;
      text-align: right;
    }
  }
}
.external-links-mobile {
  display: none;
}

//responsive design
@media only screen and (max-width: 640px) {
  .intro {
    flex-direction: column-reverse;
    justify-content: flex-end;
    height: 100vh;
    top: 100px;
  }
  .intro-text-container {
    margin-top: 10px;
    padding: 10px 0px 0px 20px;
    z-index: 2;
    height: 60vh;
    flex: 0.7;
    .intro-text {
      padding-top: 0px;
      padding-left: 10px;
      flex: 1;
    }
    h3,
    h2,
    h1 {
      height: 90px;
      overflow-y: visible;
      font-size: 2em;
    }
    a {
      display: none;
    }
  }
  .photo-container {
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 5px;
    height: 40vh;
    min-height: 300px;
    flex: 0.5;
    display: none;

    .rectangle {
      display: none;
    }
    .photo-circle {
      max-width: 200px;
      min-width: 50px;
      min-height: 150px;
      height: 30%;
      flex: 0.6;
      opacity: 0.5;
      margin-top: 30px;
      display: none;
    }
    .external-links-mobile {
      display: flex;
      justify-content: flex-end;
      height: 100px;
      width: 90%;
      flex: 0.2;
      z-index: 2;
      min-height: 50px;
      margin-top: 40px;
      padding: 0px 15px;
      a {
        height: 100%;
        margin-right: 10px;
        img {
          height: auto;
          max-height: 100%;
          max-width: 90%;
          flex: 0.3;
        }
        .resume-box-mobile {
          display: inline-block;
          text-decoration: none;
          color: $mainGrey;
          width: 90%;
          font-size: 20px;
          flex: 0.4;
        }
      }
    }
  }
  .external-links {
    display: none;
  }
  .intro::before {
    height: 100vh;
  }
}

@media only screen and (max-width: 640px) {
  .intro-text-container {
    h3 {
      font-size: 1.15em;
    }
    h2,
    h1 {
      font-size: 2em;
    }
  }
}
