@import "../../global.scss";

.navbar {
  width: 100%;
  height: 12vh;
  background-color: $mainGrey;
  color: white;
  // border-top: 2px solid white;
  border-bottom: 2px solid white;

  position: fixed;
  top: 0;
  z-index: 7;

  .wrapper {
    height: 100%;
    padding: 5px 25px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .left {
      height: 100%;
      .logo {
        height: 100%;
        font-size: 45px;
        font-weight: 500;
        text-decoration: none;
        color: inherit;
        img {
          height: 90%;
        }
      }
    }
    .right {
      display: flex;
      width: 45%;
      min-width: 405px;
      justify-content: flex-end;
      align-items: center;
      .links {
        display: flex;
        a {
          margin-right: 30px;
          &:hover {
            text-shadow: 2.5px 2.5px 0px lightsalmon;
          }
        }
      }
      .header-links {
        font-size: 25px;
        font-weight: 500;
        text-decoration: none;
        color: inherit;
      }
      .contact-header-link {
        min-width: 130px;
        margin-right: 15px !important;
      }

      .hamburger {
        width: 30px;
        height: 40px;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        color: white;

        span {
          width: 100%;
          height: 4px;
          background-color: white;
          transform-origin: left;
          transition: all 1.5s;
          transition-timing-function: cubic-bezier(0, 0, 0.5, 1);
        }
      }
    }
  }
  &.active {
    background-color: $mainGrey;
    color: white;
    // border-top: 2px solid $mainGrey;
    // border-bottom: 2px solid $mainGrey;

    .hamburger {
      span {
        &:nth-child(1) {
          transform: rotate(45deg);
        }
        &:nth-child(2) {
          opacity: 0;
          transition: none;
        }
        &:nth-child(3) {
          transform: rotate(-45deg);
        }
      }
    }
  }
}

@media only screen and (max-width: 640px) {
  .navbar {
    z-index: 7;
    width: 100%;
    height: 80px !important;
    border-top: none;

    .wrapper {
      height: 80px;
      .right {
        flex: 0.75;
        height: 100%;
        width: 50%;
        min-width: 100px;
        align-items: flex-start;
        .links {
          display: none;
        }
        .hamburger {
          height: 60%;
          width: 10%;
          min-width: 40px;
          margin-top: 10px;
          span {
            height: 5px;
            // &:nth-child(1) {
            //   transform: rotate(50deg);
            // }
            // &:nth-child(2) {
            //   opacity: 0;
            //   transition: none;
            // }
            // &:nth-child(3) {
            //   transform: rotate(-50deg);
            // }
          }
        }
      }
      .left {
        flex: 0.25;
        height: 100px;
        min-width: 90px;
        padding-bottom: 5px;
        padding-top: 5px;
        display: flex;
        align-items: center;
        margin: auto;
        .logo {
          z-index: 7;
          width: 50%;
          height: 90%;
          img {
            width: 100%;
            height: auto;
          }
        }
      }
    }
    &.active {
      border-bottom: 2px solid $mainGrey;

      .hamburger {
        padding-left: 10px;
        span {
          &:nth-child(1) {
            transform: rotate(45deg);
            width: 96%;
          }
          &:nth-child(2) {
            opacity: 0;
            transition: none;
          }
          &:nth-child(3) {
            transform: rotate(-45deg);
            width: 96%;
          }
        }
      }
    }
  }
}
